<template>
    <div v-if="fair" class="fair-view-container">
        <div class="fair-view">
            <div class="fair-view__head">
                <div class="fair-view__head-thumb">
                    <img :src="fair.thumb[$i18n.locale]" alt="">
                </div>
                <div class="fair-view__head-contents">
                    <p>{{ $dayjs(fair.open.startedAt).format("YYYY-MM-DD") }} ~ {{ $dayjs(fair.open.endedAt).format("YYYY-MM-DD") }}</p>
                    <p class="tit">{{ fair.name[$i18n.locale] }}</p>
                    <p class="txt">{{ fair.summary[$i18n.locale] }}</p>
                </div>
                <div class="fair-view__buttons">
                    <a v-if="scope.includes('seller') && fair.applicationStatus == '접수가능' && !fair.products.find(({ _seller }) => payload._user == _seller)" @click="$emit('apply', fair)" class="button button--round button--primary" href="#"><span>{{$t('common.신청하기')}}</span></a>
                    <a v-if="scope.includes('seller') && fair.products.find(({ _seller }) => payload._user == _seller)" class="button button--round button--pink" href="#" onclick="event.preventDefault()"><span>{{$t('common.참여중')}}</span></a>
                    <a v-if="fair.status == '종료'" class="button button--round button--grey disabled" href="#" aria-disabled="true" onclick="event.preventDefault()"><span>{{$t('common.종료')}}</span></a>
                </div>
            </div>
            <div class="fair-view__body">
                <slot v-bind:fair="fair"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import FairModal from "./fair-modal.vue";

export default {
    components: {
        FairModal
    },
    props: {
        type: String,
    },
    data() {
        return{
            fair: null,
            product : null,
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            let { fair } = await api.v1.fairs.get({ _id: this.$route.params._fair });
            this.fair = fair;
        },
    },
    computed: {
        payload(){
            return this.$store.state.payload || {};
        },
        scope(){
            return this.payload.scope || [];
        }
    }
};
</script>
<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";

.tab-contents{
    margin-top: 50px;
    .item { transition: all 0.15s ease; background: #fff; }
}
.v-leave-active { position: absolute; }
.v-enter { opacity: 1; }
.v-leave-to { opacity: 0; }

.fair-view{
    &__head{
        display: flex; margin-bottom: 80px; font-size: 14px; color: #999;

        .tit { margin: 2px 0 12px; color: $color-primary; }
        .txt { font-size: 18px; color: #666666; }

        &-thumb { overflow: hidden; flex: 0 0 350px; height: 250px; background-color: #f8f8f8; border: 1px solid $border; border-radius: 10px; }
        &-thumb img { display: block; width: 100%; height: auto; }
        &-contents {
            flex: 1 1 auto; display: flex; flex-direction: column; justify-content: center; margin-left: 25px;
            border-top: 1px solid $border-dark; border-bottom: 1px solid $border;
        }
    }
    &__buttons{
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 0 0 150px;
        border-top: 1px solid $border-dark; border-bottom: 1px solid $border;
        .button {
            margin: 5px;
            width: 100%;
        }
    }
}
@media(max-width:1200px){
    
}
@media(max-width:768px){
    .fair-view{
        &__head{
            flex-direction: column; font-size: 13px;
            .tit { margin: 6px 0 10px; }
            .txt { font-size: 16px; color: #666666; }
            &-thumb { flex-basis: auto; position: relative; padding-top: 100%; height: 0; }
            &-thumb img { position: absolute; top: 0; left: 0; height: 100%; object-fit: cover; }
            &-contents {
                margin: 30px 0 0; border-top: none; border-bottom: none;
            }
        }
    }
}
</style>