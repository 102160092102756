<template>
    <div class="fair-list-container">
        <div class="fair-list-wrapper">
            <slot :fairs="fairs">
                <fair-list-item v-for="(fair, index) in fairs" :fair="fair" :key="fair._id" :color="index % 2 ? 'orange': 'primary'"></fair-list-item>
            </slot>
        </div>

        <slot name="pagination">
            <pagination-component v-model="page" :count="pageCount" @input="search"></pagination-component>
        </slot>
    </div>
</template>

<script>
import api from "@/api";

import FairListItem from "@/components/client/fair/fair-list-item.vue";
import PaginationComponent from "@/components/client/control/pagination-component.vue";

export default {
    components: {
        FairListItem,
        PaginationComponent
    },
    props: {
        type: { type: String, default: "basic" },
    },
    data(){
        return {
            filter: {
                status: null,
                ...this.$route.query,
                ...this.$attrs
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            fairs: []
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            await this.search();
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
            }

            switch(this.type){
                case "basic": {
                    let { summary, fairs } = await api.v1.fairs.gets({
                        headers: {
                            skip: (this.page - 1) * this.limit,
                            limit: this.limit
                        },
                        params: this.filter
                    });

                    this.pageCount = Math.ceil(summary.totalCount / this.limit);
                    this.fairs = fairs;
                    break;
                }
                case "mypage": {
                    let { summary, fairs } = await api.v1.me.fairs.gets({
                        headers: {
                            skip: (this.page - 1) * this.limit,
                            limit: this.limit
                        },
                        params: this.filter
                    });

                    this.pageCount = Math.ceil(summary.totalCount / this.limit);
                    this.fairs = fairs;
                    break;
                }
            }
        },
    },
    watch: {
        "$attrs"(){
            this.filter = {
                ...this.filter,
                ...this.$attrs
            };

            this.page = 1;
            this.search();
        }
    }
};
</script>
<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";

.fair-list-container { border-top: 1px solid $border-dark; }
::v-deep .fair-list{
    display: flex; flex-wrap:wrap; align-items: center;
    padding: 25px 20px; font-size: 16px; color: #999999; border-bottom: 1px solid $border;

    .badge { display:block; line-height: 1; font-style: normal; color: $color-primary; }
    .tit{
        @include line-clamp(2);
        display: block;
        margin: 0 0 40px; line-height: 1.2;
    }
    .txt { line-height: 1; font-size: 14px; }

    &__body { width: calc(100% - 150px); display: flex; align-items: center; }
    &__thumb { overflow: hidden; position: relative; max-width: 280px; width: 100%; height: 200px; background-color: #f8f8f8; border: 1px solid $border; border-radius: 10px; }
    &__thumb img { display: block; }
    &__contents { flex: 1 1 auto; padding: 10px 24px; }
    &__buttons { width:150px; }
    &__buttons .button { margin: 5px; width: 100%; }
}
@media(max-width:1200px){
    ::v-deep .fair-list {
        font-size: 15px;

        &__thumb { max-width: 240px; height: 160px; }
    }
}
@media(max-width:768px){
    ::v-deep .fair-list {
        flex-direction: column; padding: 35px 0; font-size: 14px;

        .tit { margin: 5px 0 15px; }

        &__body { flex: 1 1 100%; flex-direction: column; width: 100%; }
        &__thumb { padding-top: 100%; max-width: none; height: 0; }
        &__thumb img { position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; }
        &__contents { flex: 1 1 auto; margin-top: 20px; padding: 0; width: 100%; }
        &__buttons { flex: 1 1 auto; display: flex; margin-top: 25px; width: 100%; }
        &__buttons .button { margin: 2px; }
    }
}
</style>