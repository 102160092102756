var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fair-list-container"
  }, [_c('div', {
    staticClass: "fair-list-wrapper"
  }, [_vm._t("default", function () {
    return _vm._l(_vm.fairs, function (fair, index) {
      return _c('fair-list-item', {
        key: fair._id,
        attrs: {
          "fair": fair,
          "color": index % 2 ? 'orange' : 'primary'
        }
      });
    });
  }, {
    "fairs": _vm.fairs
  })], 2), _vm._t("pagination", function () {
    return [_c('pagination-component', {
      attrs: {
        "count": _vm.pageCount
      },
      on: {
        "input": _vm.search
      },
      model: {
        value: _vm.page,
        callback: function ($$v) {
          _vm.page = $$v;
        },
        expression: "page"
      }
    })];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }