<template>
    <v-responsive width="100%" class="full-width">
        <v-row>
            <v-col
                cols="12"
                md="auto"
            >
                <v-radio-group v-model="schedule.kind" row>
                    <v-radio :label="$t('common.온라인')" value="online"></v-radio>
                    <v-radio :label="$t('common.오프라인')" value="offline"></v-radio>
                </v-radio-group>
                <div class="text-explain mb-2">
                    <p>{{$t('schedule_dialog.buyer.ex1')}}</p>
                    <p>{{$t('schedule_dialog.buyer.ex2')}}</p>
                    <small>{{$t('schedule_dialog.buyer.ex3')}}</small>
                </div>
            </v-col>
            <v-col 
                cols="12"
                md="auto"
                class="ml-auto"
            >
                <v-btn depressed rounded color="primary" @click="save">{{$t('common.저장')}}</v-btn>
            </v-col>
        </v-row>
        
        <v-data-table v-if="fair" :headers="headers" :items="schedules" width="100%" hide-default-footer hide-default-header>
            <template v-slot:header="{ props }">
                <thead>
                    <tr>
                        <th v-for="(head, index) in props.headers" :key="index">{{ head.text }}</th>
                    </tr>
                </thead>
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                        <td>{{ item.time  }}</td>
                        <td v-for="date in dates" :key="date" :class="{ 'light-blue lighten-5': !already({ date, time: item.time }), 'pink lighten-2': already({ date, time: item.time }) }">
                            <v-layout justify-center>
                                <span v-if="already({ date, time: item.time })" class="white--text">{{ already({ date, time: item.time }).seller.companyName[$i18n.locale] }}</span>
                                <v-simple-checkbox v-else-if="schedule.schedules.find(schedule => schedule.date == date && schedule.time == item.time)" class="mt-0" hide-details :value="true" color="light-blue darken-1" @click.prevent="schedule.schedules = schedule.schedules.filter(schedule => schedule.date != date || schedule.time != item.time)"></v-simple-checkbox>
                                <v-simple-checkbox v-else class="mt-0" hide-details :value="false" color="primary" @click.prevent="schedule.schedules.push({ date, time: item.time })"></v-simple-checkbox>
                            </v-layout>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
    </v-responsive>
</template>
<script>
import api from "@/api";
import dayjs from "dayjs";

export default {
    props: ["fair"],
    data(){
        return {
            schedule: {
                _id: null,
                _fair: this.$props.fair._id,
                schedules: [],
                kind: "offline"
            },

            matchings: []
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            var { schedule } = await api.v1.me.schedules.get(this.schedule);

            this.schedule = {
                ...this.schedule,
                ...schedule
            };

            var { matchings } = await api.v1.me.matchings.gets({ headers: { limit: 0 }, params: { _fair: this.fair._id } });
            this.matchings = matchings;
        },

        async save(){
            let { schedule } = this.schedule._id ? await api.v1.me.schedules.put(this.schedule) : await api.v1.me.schedules.post(this.schedule);
            this.schedule = schedule;

            alert(this.$t("common.저장되었습니다"));
            this.$emit("save");
        },

        already({ date, time }){
            return this.matchings.find(matching => matching.schedules.find(schedule => schedule.date == date && schedule.time == time));
        }
    },
    computed: {
        payload(){
            return this.$store.state.payload;
        },
        scope(){
            return this.payload?.scope || [];
        },
        dates(){
            let dates = [];
            for(let date = dayjs(this.fair.open.startedAt); true; date = date.add(1, "days")){
                let dateFormat = date.format("YYYY-MM-DD");
                if(!this.fair.closedAt.includes(dateFormat)) dates.push(dateFormat);
                if(dateFormat == dayjs(this.fair.open.endedAt).format("YYYY-MM-DD")) break;
            }

            return dates;
        },
        headers(){
            let headers = [{ text: this.$t("common.시간"), value: "time" }];
            for(let date = dayjs(this.fair.open.startedAt); true; date = date.add(1, "days")){
                let dateFormat = date.format("YYYY-MM-DD");
                if(!this.fair.closedAt.includes(dateFormat)) headers.push({ text: dateFormat, value: dateFormat });
                if(dateFormat == dayjs(this.fair.open.endedAt).format("YYYY-MM-DD")) break;
            }
            
            return headers;
        },
        schedules(){
            let schedules = [];
            for(let time = 9; time < 18; time++){
                if(time == 12) continue; // 점심시간 패스
                let timeFormat = `${time.toString().padStart(2, "0")}:00 ~ ${(time+1).toString().padStart(2, "0")}:00`;
                let schedule = {
                    time: timeFormat
                };

                // for(let date = dayjs(this.fair.open.startedAt); date.format("YYYY-MM-DD") != dayjs(this.fair.open.endedAt).format("YYYY-MM-DD"); date = date.add(1, "days")){
                //     let dateFormat = date.format("YYYY-MM-DD");
                //     schedule[dateFormat] = this.matchings.find(matching => matching?.schedules?.find(schedule => schedule.date == dateFormat ) );
                // }
                schedules.push(schedule);
            }

            return schedules;
        }
    }
}
</script>