<template id="modal-template">
    <transition v-if="show && fair" name="modal" appear>
        <div class="modal-container modal-container--scrollable">
            <div class="modal-wrapper">
                <div class="modal">
                    <div class="modal__head">
                        <h1 class="modal__title">매칭상담회에 참여할 제품을 선택해 주세요.</h1>
                        <button class="modal__close" type="button" @click="close">
                            <img src="/images/icon/close-rounded.png" alt="">
                        </button>
                    </div>
                    <div class="modal__body">
                        <!-- 참여제품 리스트(팝업 옵션: true) -->
                        <product-list show-category :fair="fair" type="me" show-pagination>
                            <template v-slot:title>
                                <div class="fair-product__head-col">
                                    <p class="tit">선택된 제품 수 : <span class="color-primary">{{ selected.length }}</span>개</p>
                                </div>
                                <div class="fair-product__head-col">
                                    <p><span class="color-primary">*</span> 최대 10개 까지 선택 가능</p>
                                </div>
                            </template>

                            <template v-slot:default="{products}">
                                <product-list-item v-model="selected" v-for="product in products" :key="product._id" :product="product" show-select></product-list-item>
                            </template>
                        </product-list>
                    </div>
                    <div class="modal__foot">
                        <!-- <div class="modal__buttons">
                            <button class="button button--primary" type="button" @click="save"><span>신청하기</span></button>
                        </div> -->
                        <div class="modal__foot__buttons">
                            <a class="button button--primary button--round" href="#" onlick="event.preventDefault()" @click="save"><span>신청하기</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>


<script>
import api from "@/api";
import ProductList from "@/components/client/fair/product-list.vue";
import ProductListItem from "@/components/client/fair/product-list-item.vue";

export default {
    components: {
        ProductList,
        ProductListItem
    },
    data() {
        return {
            show: false,
            fair: null,
            selected: [],
        };
    },
    methods: {
        open(){
            this.show = true;
            document.querySelector('html').setAttribute('data-modal', 'opened');
        },
        close(){
            this.show = false;
            document.querySelector('html').removeAttribute('data-modal');
        },
        validate(){
            try{
                if(!this.selected.length) throw new Error("작품을 전택해주세요");
                return true;
            }
            catch(error){
                alert(error.message);
            }
            return false;
        },
        async save(){
            if(this.validate()){
                let { fair: { products } } = await api.v1.fairs.products.post(this.fair._id, {
                    _products: this.selected
                });

                this.fair._products = this.selected;
                this.fair.products = products;
                
                alert("신청이 완료되었습니다");
                this.close();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.modal__foot{
    &__buttons { display: flex; justify-content: center; margin-top: 50px; }
    &__buttons .button { padding: 10px 40px 12px; line-height: 1; }
}

</style>