<template id="modal-template">
    <transition v-if="show && matching" name="modal" appear>
        <div class="modal-container modal-container--scrollable">
            <div class="modal-wrapper">
                <div class="modal">
                    <div class="modal__head">
                        <h1 class="modal__title">
                            <!-- <span>({{ matching.kind == "online" ? "온라인" : "오프라인" }}) 매칭 가능한 일시를 선택해주세요.</span> -->
                            <span>매칭상담을 진행할 일시를 선택해 주세요</span>
                            <small>※ 아래 일정은 제조기업에서 매칭상담이 가능한 일정입니다. 최종적으로 하나의 스케줄을 확정 부탁드립니다.</small>
                        </h1>
                        <button class="modal__close" type="button" @click="close">
                            <img src="/images/icon/close-rounded.png" alt="">
                        </button>
                    </div>
                    <div class="modal__body">
                        <v-layout justify-center>
                            <v-radio-group v-model="select">
                                <v-radio v-for="(schedule, index) in matching.schedules" :key="index" :label="`${schedule.date} ${schedule.time}`" :value="schedule"></v-radio>
                            </v-radio-group>
                        </v-layout>
                    </div>
                    <div class="modal__foot">
                        <!-- <div class="modal__buttons">
                            <button class="button button--primary" type="button" @click="save"><span>신청하기</span></button>
                        </div> -->
                        <div class="modal__foot__buttons">
                            <a class="button button--primary button--round" href="#" onlick="event.preventDefault()" @click="confirm"><span>수락하기</span></a>
                            <a class="button button--border button--round" href="#" onlick="event.preventDefault()" @click="reject"><span>거절하기</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>


<script>
import api from "@/api";

export default {
    data() {
        return {
            show: false,
            select: null,
            matching: null
        };
    },
    methods: {
        open(){
            this.show = true;
            this.select = this.matching.schedules[0];
            document.querySelector('html').setAttribute('data-modal', 'opened');
        },
        close(){
            this.show = false;
            document.querySelector('html').removeAttribute('data-modal');
        },
        async confirm(){
            await api.v1.me.matchings.put({
                _id: this.matching._id,
                schedules: [this.select],
                status: "매칭확정"
            });
            alert("매칭상담 스케줄이 확정되었습니다");
            this.$emit("confirm");
            this.close();
        },
        async reject(){
            if(confirm("거절하시겠습니까?")){
                await api.v1.me.matchings.put({
                    _id: this.matching._id,
                    status: "매칭거절"
                });
                alert("매칭상담이 거절되었습니다");
                this.$emit("reject");
                this.close();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.modal__foot{
    &__buttons { display: flex; justify-content: center; margin-top: 50px; }
    &__buttons .button { padding: 10px 40px 12px; margin:2px; line-height: 1; }
}

</style>