var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('p', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.label))]), _c('p', {
    staticClass: "txt"
  }, [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }