<template id="modal-template">
    <transition v-if="show && consulting" name="modal" appear>
        <div class="modal-container modal-container--scrollable">
            <div class="modal-wrapper">
                <div class="modal">
                    <div class="modal__head">
                        <h1 class="modal__title">
                            <span>상담일지 작성</span>
                        </h1>
                        <button class="modal__close" type="button" @click="close">
                            <img src="/images/icon/close-rounded.png" alt="">
                        </button>
                    </div>
                    <div class="modal__body">
                        <div class="row-table">
                            <table-row label="날짜">
                                {{ $dayjs(consulting.writedAt).format("YYYY-MM-DD HH:mm:ss") }}
                            </table-row>
                            <table-row label="바이어">
                                {{ matching.buyer.companyName[$i18n.locale] }}
                            </table-row>
                            <table-row label="셀러">
                                {{ matching.seller.companyName[$i18n.locale] }}
                            </table-row>
                        </div>

                        <div class="row-table mt-4">
                            <table-row label="1.주력품목(셀러)">
                                <v-text-field v-model="consulting.content1" outlined></v-text-field>
                            </table-row>
                            <table-row label="2.주력시장 및 타겟(셀러)">
                                <v-text-field v-model="consulting.content2" outlined></v-text-field>
                            </table-row>
                            <table-row label="3.상담상품 정보">
                                <v-text-field v-model="consulting.content3" outlined placeholder="※ 상담 상품의 인증 정보 등 정보 기재"></v-text-field>
                            </table-row>
                            <table-row label="4.거래 예상규모">
                                <v-textarea v-model="consulting.content4" outlined placeholder="※ 주력 판로 및 타겟층 기재"></v-textarea>
                            </table-row>
                            <table-row label="5.상담내용">
                                <v-textarea v-model="consulting.content5" outlined placeholder="※ 매칭 상담 시 논의되었던 내용(거래 조건, 향후 마케팅 구조, 상담 내용 등)을 상세히 기재"></v-textarea>
                            </table-row>
                        </div>

                        <div class="row-table mt-4">
                            <table-row label="취급의사">
                                <v-radio-group v-model="consulting.intention" row>
                                    <v-radio label="있음" :value="true"></v-radio>
                                    <v-radio label="없음" :value="false"></v-radio>
                                </v-radio-group>
                            </table-row>
                            <table-row label="상담만족">
                                <v-radio-group v-model="consulting.satisfaction" row>
                                    <span v-for="star in 5" :key="star" @click="consulting.satisfaction = star">
                                        <v-radio :value="star" class="d-inline ma-0"></v-radio>
                                        <v-rating :value="star" color="primary" background-color="primary" class="d-inline" dense readonly></v-rating>
                                    </span>
                                </v-radio-group>
                            </table-row>
                            <table-row label="후속진행">
                                <v-checkbox v-model="consulting.followUp" label="이메일" value="email" class="d-inline-block mr-2"></v-checkbox>
                                <v-checkbox v-model="consulting.followUp" label="샘플구매" value="sample" class="d-inline-block mr-2"></v-checkbox>
                                <v-checkbox v-model="consulting.followUp" label="기업방문" value="visit" class="d-inline-block mr-2"></v-checkbox>
                                <v-checkbox v-model="consulting.followUp" label="의사없음" value="none" class="d-inline-block mr-2"></v-checkbox>
                            </table-row>
                        </div>
                    </div>
                    <div class="modal__foot">
                        <div class="modal__foot__buttons">
                            <a class="button button--primary button--round" href="#" onlick="event.preventDefault()" @click="save"><span>저장하기</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>


<script>
import api from "@/api";
import TableRow from "./table-row.vue";

export default {
    components: {
        TableRow
    },
    data() {
        return {
            show: false,
            matching: null,
            consulting: null
        };
    },
    methods: {
        open(){
            this.consulting = {
                content1: null,
                content2: null,
                content3: null,
                content4: null,
                content5: null,
                intention: null,
                satisfaction: null,
                followUp: [],
                writedAt: Date.now(),
                ...this.matching.consulting
            }

            this.show = true;
            document.querySelector('html').setAttribute('data-modal', 'opened');
        },
        close(){
            this.show = false;
            document.querySelector('html').removeAttribute('data-modal');
        },
        async save(){
            await api.v1.me.matchings.put({
                _id: this.matching._id,
                consulting: this.consulting
            });
            alert("저장되었습니다");
            this.$emit("save");
            this.close();
        },
    },
}
</script>
<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.modal__foot{
    &__buttons { display: flex; justify-content: center; margin-top: 50px; }
    &__buttons .button { padding: 10px 40px 12px; line-height: 1; }
}

</style>