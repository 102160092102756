var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.show && _vm.buyer ? _c('transition', {
    attrs: {
      "name": "modal",
      "appear": ""
    }
  }, [_c('div', {
    staticClass: "modal-container modal-container--scrollable"
  }, [_c('div', {
    staticClass: "modal-wrapper"
  }, [_c('div', {
    staticClass: "modal"
  }, [_c('div', {
    staticClass: "modal__head"
  }, [_c('h1', {
    staticClass: "modal__title"
  }, [_c('span', [_vm._v(_vm._s(_vm.buyer.companyName[_vm.$i18n.locale]))])]), _c('button', {
    staticClass: "modal__close",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/close-rounded.png",
      "alt": ""
    }
  })])]), _c('div', {
    staticClass: "modal__body"
  }, [_c('div', {
    staticClass: "row-table"
  }, [_c('table-row', {
    attrs: {
      "label": "사업분야"
    }
  }, [_vm._v(" " + _vm._s(_vm.buyer.businessAreas) + " ")]), _c('table-row', {
    attrs: {
      "label": "기업명"
    }
  }, [_vm._v(" " + _vm._s(_vm.buyer.companyName[_vm.$i18n.locale]) + " ")]), _c('table-row', {
    attrs: {
      "label": "설립년도"
    }
  }, [_vm._v(" " + _vm._s(_vm.buyer.foundedAt) + " ")]), _c('table-row', {
    attrs: {
      "label": "주소"
    }
  }, [_vm._v(" " + _vm._s(_vm.buyer.companyAddress) + " ")]), _c('table-row', {
    attrs: {
      "label": "바이어 유형"
    }
  }, [_vm._v(" " + _vm._s(_vm.buyer.kind.join(",")) + " ")]), _c('table-row', {
    attrs: {
      "label": "대표자 이름"
    }
  }, [_vm._v(" " + _vm._s(_vm.buyer.ceoName) + " ")]), _c('table-row', {
    attrs: {
      "label": "회사 소재국가"
    }
  }, [_vm._v(" " + _vm._s(_vm.buyer.countryOfOrigin) + " ")]), _c('table-row', {
    attrs: {
      "label": "바이어 활동지역"
    }
  }, [_vm._v(" " + _vm._s(_vm.buyer.activityAreas[_vm.$i18n.locale]) + " ")]), _c('table-row', {
    attrs: {
      "label": "주요 유통망(온라인)"
    }
  }, [_vm._v(" " + _vm._s(_vm.buyer.onlineCirculation) + " ")]), _c('table-row', {
    attrs: {
      "label": "주요 유통망(오프라인)"
    }
  }, [_vm._v(" " + _vm._s(_vm.buyer.offlineCirculation) + " ")]), _c('table-row', {
    attrs: {
      "label": "회사소개"
    }
  }, [_vm._v(" " + _vm._s(_vm.buyer.aboutUs) + " ")]), _c('table-row', {
    attrs: {
      "label": "관심상품군"
    }
  }, [_vm._v(" " + _vm._s(_vm.buyer.interestedGroups.map(function (interest) {
    return _vm.categories.find(function (_ref) {
      var _id = _ref._id;
      return _id == interest;
    }).name[_vm.$i18n.locale];
  }).join(", ")) + " ")]), _c('table-row', {
    attrs: {
      "label": "소싱 희망상품"
    }
  }, [_vm._v(" " + _vm._s(_vm.buyer.sourcings) + " ")]), _c('table-row', {
    attrs: {
      "label": "주요 경력"
    }
  }, [_vm._v(" " + _vm._s(_vm.buyer.career) + " ")]), _c('table-row', {
    attrs: {
      "label": "홈페이지 주소"
    }
  }, [_c('a', {
    attrs: {
      "href": _vm.homepage
    }
  }, [_vm._v(_vm._s(_vm.buyer.homepage))])]), _c('table-row', {
    attrs: {
      "label": "회사소개서"
    }
  }, [_vm._v(" " + _vm._s(_vm.buyer.introduction) + " ")])], 1)]), _c('div', {
    staticClass: "modal__foot"
  })])])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }