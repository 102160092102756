<template id="modal-template">
    <transition v-if="show && buyer" name="modal" appear>
        <div class="modal-container modal-container--scrollable">
            <div class="modal-wrapper">
                <div class="modal">
                    <div class="modal__head">
                        <h1 class="modal__title">
                            <span>{{ buyer.companyName[$i18n.locale] }}</span>
                        </h1>
                        <button class="modal__close" type="button" @click="close">
                            <img src="/images/icon/close-rounded.png" alt="">
                        </button>
                    </div>
                    <div class="modal__body">
                        <div class="row-table">
                            <table-row label="사업분야">
                                {{ buyer.businessAreas }}
                            </table-row>
                            <table-row label="기업명">
                                {{ buyer.companyName[$i18n.locale] }}
                            </table-row>
                            <table-row label="설립년도">
                                {{ buyer.foundedAt }}
                            </table-row>
                            <table-row label="주소">
                                {{ buyer.companyAddress }}
                            </table-row>
                            <table-row label="바이어 유형">
                                {{ buyer.kind.join(",") }}
                            </table-row>
                            <table-row label="대표자 이름">
                                {{ buyer.ceoName }}
                            </table-row>
                            <table-row label="회사 소재국가">
                                {{ buyer.countryOfOrigin }}
                            </table-row>
                            <table-row label="바이어 활동지역">
                                {{ buyer.activityAreas[$i18n.locale] }}
                            </table-row>
                            <table-row label="주요 유통망(온라인)">
                                {{ buyer.onlineCirculation }}
                            </table-row>
                            <table-row label="주요 유통망(오프라인)">
                                {{ buyer.offlineCirculation }}
                            </table-row>
                            <table-row label="회사소개">
                                {{ buyer.aboutUs }}
                            </table-row>
                            <table-row label="관심상품군">
                                {{ buyer.interestedGroups.map(interest => categories.find(({ _id }) => _id == interest).name[$i18n.locale] ).join(", ") }}
                            </table-row>
                            <table-row label="소싱 희망상품">
                                {{ buyer.sourcings }}
                            </table-row>
                            <table-row label="주요 경력">
                                {{ buyer.career }}
                            </table-row>
                            <table-row label="홈페이지 주소">
                                <a :href="homepage">{{ buyer.homepage }}</a>
                            </table-row>
                            <table-row label="회사소개서">
                                {{ buyer.introduction }}
                            </table-row>
                        </div>
                    </div>
                    <div class="modal__foot">
                        <!-- <div class="modal__foot__buttons">
                            <a class="button button--primary button--round" href="#" onlick="event.preventDefault()" @click="confirm"><span>수락하기</span></a>
                            <a class="button button--border button--round" href="#" onlick="event.preventDefault()" @click="reject"><span>거절하기</span></a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>


<script>
import api from "@/api";
import TableRow from "./table-row.vue";
export default {
    components: {
        TableRow
    },
    data() {
        return {
            show: false,
            buyer: null,
            categories: [],
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            let { categories } = await api.v1.shop.categories.gets({ params: { depth: 1 } });
            this.categories = categories;
        },
        open(){
            this.show = true;
            document.querySelector('html').setAttribute('data-modal', 'opened');
        },
        close(){
            this.show = false;
            document.querySelector('html').removeAttribute('data-modal');
        },
    }
}
</script>
<style lang="scss">
@import "@/styles/_variables.scss";

.modal__foot{
    &__buttons { display: flex; justify-content: center; margin-top: 50px; }
    &__buttons .button { padding: 10px 40px 12px; line-height: 1; }
}

</style>