var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.fair ? _c('div', {
    staticClass: "fair-view-container"
  }, [_c('div', {
    staticClass: "fair-view"
  }, [_c('div', {
    staticClass: "fair-view__head"
  }, [_c('div', {
    staticClass: "fair-view__head-thumb"
  }, [_c('img', {
    attrs: {
      "src": _vm.fair.thumb[_vm.$i18n.locale],
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "fair-view__head-contents"
  }, [_c('p', [_vm._v(_vm._s(_vm.$dayjs(_vm.fair.open.startedAt).format("YYYY-MM-DD")) + " ~ " + _vm._s(_vm.$dayjs(_vm.fair.open.endedAt).format("YYYY-MM-DD")))]), _c('p', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.fair.name[_vm.$i18n.locale]))]), _c('p', {
    staticClass: "txt"
  }, [_vm._v(_vm._s(_vm.fair.summary[_vm.$i18n.locale]))])]), _c('div', {
    staticClass: "fair-view__buttons"
  }, [_vm.scope.includes('seller') && _vm.fair.applicationStatus == '접수가능' && !_vm.fair.products.find(function (_ref) {
    var _seller = _ref._seller;
    return _vm.payload._user == _seller;
  }) ? _c('a', {
    staticClass: "button button--round button--primary",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('apply', _vm.fair);
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('common.신청하기')))])]) : _vm._e(), _vm.scope.includes('seller') && _vm.fair.products.find(function (_ref2) {
    var _seller = _ref2._seller;
    return _vm.payload._user == _seller;
  }) ? _c('a', {
    staticClass: "button button--round button--pink",
    attrs: {
      "href": "#",
      "onclick": "event.preventDefault()"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('common.참여중')))])]) : _vm._e(), _vm.fair.status == '종료' ? _c('a', {
    staticClass: "button button--round button--grey disabled",
    attrs: {
      "href": "#",
      "aria-disabled": "true",
      "onclick": "event.preventDefault()"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('common.종료')))])]) : _vm._e()])]), _c('div', {
    staticClass: "fair-view__body"
  }, [_vm._t("default", null, {
    "fair": _vm.fair
  })], 2)])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }