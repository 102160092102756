<template>
<div>
    <!-- 셀러 매칭 목록 -->
    <v-data-table v-if="scope.includes('seller')" :items="matchings" :headers="headers">
        <template v-slot:item.product="{item}">
            {{ item.product.name[$i18n.locale] }}
        </template>
        <template v-slot:item.buyer="{item}">
            <v-btn text @click="$refs.buyerDialog.buyer = item.buyer; $refs.buyerDialog.open();" class="btn-modal">{{ item.buyer.companyName[$i18n.locale] }}<v-icon>mdi-chevron-right</v-icon></v-btn>
        </template>
        <template v-slot:item.status="{item}">
            <span>{{ item.status }}</span>
            <div>
                <span v-if="item.kind == 'online'">온라인</span>
                <span v-if="item.kind == 'offline'">오프라인</span>
            </div>
            <div v-for="(schedule, index) in item.schedules" :key="index">{{ schedule.date }} {{ schedule.time }}</div>
        </template>
        <template v-slot:item.details="{item}">
            <!-- <v-btn depressed outlined rounded color="grey">수정하기</v-btn> -->
            <v-btn v-if="item.status == '매칭중' && !item.schedules.length" depressed rounded color="primary" @click="$emit('click', { matchings, matching: item})">매칭신청</v-btn>
        </template>
    </v-data-table>

    <!-- 바이어 매칭 목록-->
    <v-data-table v-else-if="scope.includes('buyer')" :items="matchings" :headers="headers">
        <template v-slot:item.product.thumb="{item}">
            <v-layout justify-center>
                <v-img width="128" max-height="128" contain :src="item.product.thumb"></v-img>
            </v-layout>
        </template>
        <template v-slot:item.product.name="{item}">
            {{ item.product.name[$i18n.locale] }}
        </template>
        <template v-slot:item.product.seller.companyName="{item}">
            {{ item.product.seller.companyName[$i18n.locale] }}
        </template>
        <template v-slot:item.status="{item}">
            <v-btn v-if="item.status == '매칭중' && item.schedules.length" depressed rounded color="primary" @click="$refs.matchingDialog.matching = item; $refs.matchingDialog.open();">스케줄 확정</v-btn>
            <span v-else>{{ item.status }}</span>
            <div>
                <span v-if="item.kind == 'online'">온라인</span>
                <span v-if="item.kind == 'offline'">오프라인</span>
            </div>
            <div v-for="(schedule, index) in item.schedules" :key="index">{{ schedule.date }} {{ schedule.time }}</div>
        </template>
        <template v-slot:item.confirmedAt="{item}">
            <span v-if="item.confirmedAt">{{ $dayjs(item.confirmedAt).format("YYYY-MM-DD HH:mm:ss") }}</span>
            <span v-else>-</span>
        </template>
    </v-data-table>
    
    <matching-dialog ref="matchingDialog" @confirm="search" @reject="search"></matching-dialog>
    <buyer-dialog ref="buyerDialog"></buyer-dialog>
</div>
</template>

<script>
import api from "@/api";
import MatchingDialog from "./matching-dialog.vue";
import BuyerDialog from '../mypage/buyer-dialog.vue';

export default {
    components: {
        MatchingDialog,
        BuyerDialog
    },
    data(){
        return {
            filter: {
                ...this.$attrs
            },

            matchings: [],
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.search();
        },
        async search(){
            let { matchings } = await api.v1.me.matchings.gets({
                header: { limit: 0 },
                params: this.filter
            });

            this.matchings = matchings;
        }
    },
    computed: {
        payload(){
            return this.$store.state.payload;
        },
        scope(){
            return this.payload?.scope || [];
        },
        headers(){
            if(this.scope.includes("seller")){
                return [
                    { text: "제품명", align: "center", value: "product" },
                    { text: "기업명", align: "center", value: "buyer" },
                    { text: "매칭상태", align: "center", value: "status" },
                    { text: "매칭신청", align: "center", value: "details" },
                ];
            }
            if(this.scope.includes("buyer")){
                return [
                    { text: "제품사진", align: "center", value: "product.thumb" },
                    { text: "제품명", align: "center", value: "product.name" },
                    { text: "기업명", align: "center", value: "product.seller.companyName" },
                    { text: "매칭상태", align: "center", value: "status" },
                    { text: "확정일", align: "center", value: "confirmedAt" },
                ];
            }
        }
    },
    watch: {
        $attrs(){
            this.filter = { ...this.filter, ...this.$attrs };
            this.search();
        }
    }
}
</script>
<style lang="scss">
.theme--light.v-btn.btn-modal { padding: 0; color: inherit; font-weight: 400; }
</style>