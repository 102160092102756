<template>
<v-responsive>
    <v-data-table :headers="headers" :items="matchings" hide-default-footer>
        <template v-slot:item.seller.companyName="{item}">
            {{ item.seller.companyName[$i18n.locale] }}
        </template>
        <template v-slot:item.schedules="{item}">
            <span v-if="item.schedules[0]">{{ item.schedules[0].date }} {{ item.schedules[0].time }}</span>
        </template>
        <template v-slot:item.consulting="{item}">
            <v-btn v-if="fair.status == '종료' && $dayjs(fair.open.endedAt).add(7, 'day').toDate() < $dayjs().toDate() && item.consulting.writedAt" color="grey" rounded class="white--text">작성완료</v-btn>
            <v-btn v-else-if="!item.consulting.writedAt && $dayjs().toDate() < $dayjs(fair.open.endedAt).add(7, 'day').toDate()" color="primary" rounded @click="edit(item)">작성하기</v-btn>
            <v-btn v-else-if="item.consulting.writedAt && $dayjs().toDate() < $dayjs(fair.open.endedAt).add(7, 'day').toDate()" color="white" rounded @click="edit(item)">수정하기</v-btn>
        </template>
    </v-data-table>

    <consulting-dialog ref="consultingDialog" @save="search"></consulting-dialog>

    <pagination-component v-model="page" :count="pageCount" @input="search"></pagination-component>
</v-responsive>
</template>
<script>
import api from "@/api";
import ConsultingDialog from "./consulting-dialog.vue";
import PaginationComponent from "@/components/client/control/pagination-component.vue";
export default {
    props: ["fair"],
    components: {
        ConsultingDialog,
        PaginationComponent
    },
    data(){
        return {
            filter: {
                status: "매칭확정",
                ...this.$attrs
            },

            page: 1,
            pageCount: 0,
            limit: 10,

            matchings: [],
        
            headers: [
                { text: "기업명", align: "center", value: "seller.companyName" },
                { text: "매칭일", align: "center", value: "schedules" },
                { text: "상담일지", align: "center", value: "consulting" },
            ]
        };
    },
    mounted(){
        this.search();
    },
    methods: {
        async search(){
            let { summary, matchings } = await api.v1.me.matchings.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });

            this.pageCount = Math.ceil(summary.totalCount / this.limit);
            this.matchings = matchings;
        },

        edit(matching){
            this.$refs.consultingDialog.matching = matching;
            this.$refs.consultingDialog.open();
        }
    },
    watch: {
        $attrs(){
            this.filter = {
                ...this.filter,
                ...this.$attrs
            }
        }
    }
}
</script>