<template>
	<client-page class="wrap wrap--mypage">
        <!-- 서브비주얼 -->
        <sub-visual v-if="scope.includes('seller')" :visual="$t('common.셀러 회원 마이페이지')"></sub-visual>
        <sub-visual v-if="scope.includes('buyer')" :visual="$t('common.바이어 회원 마이페이지')"></sub-visual>

		<div class="contents">
			<div class="ct">
				<div class="mypage-container">
					<!-- 네비게이션 메뉴 -->
					<mypage-navigation></mypage-navigation>
					<!-- 페이지 콘텐츠 -->
					<div class="mypage-contents">
						<div class="mypage-contents__head">
							<!-- <h2 class="mypage-contents__title"></h2> -->
						</div>
						<div class="mypage-contents__body">
							<!-- 상담회 리스트 -->
                            <fair-list v-slot:default="{ fairs }" type="mypage">
								<fair-list-item v-for="(fair, index) in fairs" :key="fair._id" :fair="fair" :to="`/mypage/fairs/${fair._id}`" type="mypage" :color="index % 2 ? 'orange': 'primary'"></fair-list-item>
							</fair-list>
						</div>
					</div>
				</div>
			</div>
		</div>
	</client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import FairList from "@/components/client/fair/fair-list.vue";
import FairListItem from "@/components/client/fair/fair-list-item.vue";

export default{
	components: {
		ClientPage,
		SubVisual,
		MypageNavigation,
        FairList,
		FairListItem,
	},
	data() {
		return {
            fairView: false,
		};
	},
    methods: {
        changeFair(status){
            this.fairView = status;
        }
    },
	computed: {
		payload(){
			return this.$store.state.payload || {};
		},
		scope(){
			return this.payload.scope || [];
		}
	}
}
</script>
