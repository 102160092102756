<template>
    <!-- 셀러 스케줄 -->
    <v-responsive width="100%" class="full-width">
        <v-row>
            <v-col
                cols="12"
                md="auto"
            >
                <p class="tit-dot">
                    <span v-if="matching.kind == 'online'">'{{ matching.buyer.companyName[$i18n.locale] }}' 온라인 매칭상담 신청</span>
                    <span v-if="matching.kind == 'offline'">'{{ matching.buyer.companyName[$i18n.locale] }}' 오프라인 매칭상담 신청</span>
                </p>
                <div class="text-explain mt-3 mb-2">
                    <p>{{$t("schedule_dialog.seller.ex1")}}</p>
                    <p>{{$t("schedule_dialog.seller.ex2")}}</p>
                    <p>{{$t("schedule_dialog.seller.ex3")}}</p>
                    <small>{{$t("schedule_dialog.seller.ex4")}}</small>
                </div>
            </v-col>
            <v-col 
                cols="12"
                md="auto"
                class="ml-auto"
            >
                <v-btn outlined rounded depressed @click="reject">{{$t("common.매칭거절")}}</v-btn>
            </v-col>
        </v-row>
        <v-data-table v-if="fair && matching" :headers="headers" :items="schedules" width="100%" hide-default-footer hide-default-header>
            <template v-slot:header="{ props }">
                <thead class="grey lighten-2">
                    <th v-for="(head, index) in props.headers" :key="index" class="py-3">{{ head.text }}</th>
                </thead>
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                        <td class="white">{{ item.time  }}</td>
                        <td v-for="date in dates" :key="date" class="white" :class="{ 'blue lighten-5': item[date] && !already({ date, time: item.time }), 'red lighten-3': already({ date, time: item.time }) }">
                            <span v-if="already({ date, time: item.time })" class="white--text">
                                
                            </span>
                            <span v-else-if="item[date]">
                                <v-btn v-if="!selected.find(select => select.date == date && select.time == item.time)" depressed rounded color="primary" small :disabled="selected.length == 3" @click="selected.push({ date, time: item.time })">{{$t("common.신청")}}</v-btn>
                                <v-btn v-else depressed rounded color="grey" small class="white--text" @click="selected = selected.filter(select => select.date != date || select.time != item.time)">{{$t("common.취소")}}</v-btn>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
        
        <a href="link"> link </a>
        <router-link to="link"> link </router-link>

        <v-row>
            <v-spacer/>
            <v-col cols="auto">
                <v-btn color="grey" depressed class="mr-2 white--text" @click="cancel">{{$t("common.취소")}}</v-btn>
                <v-btn color="primary" depressed @click="save">{{$t("common.매칭신청")}}</v-btn>
            </v-col>
        </v-row>
    </v-responsive>
</template>
<script>
import api from "@/api";
import dayjs from "dayjs";

export default {
    props: ["fair", "matching", "matchings"],
    data(){
        return {
            selected: [],
            schedule: {
                schedules: []
            },
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            let { schedule } = await api.v1.me.matchings.schedules.get(this.matching._id);
            this.schedule = schedule;
        },
        cancel(){
            this.$emit("cancel");
        },

        async save(){
            try{
                if(confirm(this.$t("schedule_dialog.application_question"))){
                    await api.v1.me.matchings.put({
                        _id: this.matching._id,
                        status: "매칭중",
                        schedules: this.selected,
                    });
    
                    alert(this.$t("schedule_dialog.application_complete"));
                    this.$emit("save");
                }
            }
            catch(error){
                this.init();
                this.selected = [];
            }
        },

        async reject(){
            if(confirm(this.$t("schedule_dialog.reject_question"))){
                await api.v1.me.matchings.put({
                    _id: this.matching._id,
                    status: "매칭거절"
                });

                alert(this.$t("schedule_dialog.reject_compelete"));
                this.$emit('reject');
            }
        },

        already({ date, time }){
            return this.matchings.find(matching => matching.schedules.find(schedule => schedule.date == date && schedule.time == time));
        }
    },
    computed: {
        dates(){
            let dates = [];
            for(let date = dayjs(this.fair.open.startedAt); true; date = date.add(1, "days")){
                let dateFormat = date.format("YYYY-MM-DD");
                if(!this.fair.closedAt.includes(dateFormat)) dates.push(dateFormat);
                if(dateFormat == dayjs(this.fair.open.endedAt).format("YYYY-MM-DD")) break;
            }

            return dates;
        },
        headers(){
            let headers = [{ text: "시간", value: "time" }];
            for(let date = dayjs(this.fair.open.startedAt); true; date = date.add(1, "days")){
                let dateFormat = date.format("YYYY-MM-DD");
                if(!this.fair.closedAt.includes(dateFormat)) headers.push({ text: dateFormat, value: dateFormat });
                if(dateFormat == dayjs(this.fair.open.endedAt).format("YYYY-MM-DD")) break;
            }
            
            return headers;
        },
        schedules(){
            let schedules = [];
            for(let time = 9; time < 18; time++){
                if(time == 12) continue; // 점심시간 패스
                let timeFormat = `${time.toString().padStart(2, "0")}:00 ~ ${(time+1).toString().padStart(2, "0")}:00`;
                let schedule = {
                    time: timeFormat
                };

                for(let date = dayjs(this.fair.open.startedAt); true; date = date.add(1, "days")){
                    let dateFormat = date.format("YYYY-MM-DD");
                    schedule[dateFormat] = this.schedule.schedules.find(schedule => !schedule._seller && schedule.date == dateFormat && schedule.time == timeFormat);
                    if(dateFormat == dayjs(this.fair.open.endedAt).format("YYYY-MM-DD")) break;
                }
                schedules.push(schedule);
            }

            return schedules;
        }
    }
}
</script>