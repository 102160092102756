<template>
    <li>
        <p class="tit">{{ label }}</p>
        <p class="txt">
            <slot></slot>
        </p>
    </li>
</template>
<script>
export default {
    props: ["label"],
}
</script>