var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.show && _vm.fair ? _c('transition', {
    attrs: {
      "name": "modal",
      "appear": ""
    }
  }, [_c('div', {
    staticClass: "modal-container modal-container--scrollable"
  }, [_c('div', {
    staticClass: "modal-wrapper"
  }, [_c('div', {
    staticClass: "modal"
  }, [_c('div', {
    staticClass: "modal__head"
  }, [_c('h1', {
    staticClass: "modal__title"
  }, [_vm._v("매칭상담회에 참여할 제품을 선택해 주세요.")]), _c('button', {
    staticClass: "modal__close",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/close-rounded.png",
      "alt": ""
    }
  })])]), _c('div', {
    staticClass: "modal__body"
  }, [_c('product-list', {
    attrs: {
      "show-category": "",
      "fair": _vm.fair,
      "type": "me",
      "show-pagination": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('div', {
          staticClass: "fair-product__head-col"
        }, [_c('p', {
          staticClass: "tit"
        }, [_vm._v("선택된 제품 수 : "), _c('span', {
          staticClass: "color-primary"
        }, [_vm._v(_vm._s(_vm.selected.length))]), _vm._v("개")])]), _c('div', {
          staticClass: "fair-product__head-col"
        }, [_c('p', [_c('span', {
          staticClass: "color-primary"
        }, [_vm._v("*")]), _vm._v(" 최대 10개 까지 선택 가능")])])];
      },
      proxy: true
    }, {
      key: "default",
      fn: function (_ref) {
        var products = _ref.products;
        return _vm._l(products, function (product) {
          return _c('product-list-item', {
            key: product._id,
            attrs: {
              "product": product,
              "show-select": ""
            },
            model: {
              value: _vm.selected,
              callback: function ($$v) {
                _vm.selected = $$v;
              },
              expression: "selected"
            }
          });
        });
      }
    }], null, false, 1343079166)
  })], 1), _c('div', {
    staticClass: "modal__foot"
  }, [_c('div', {
    staticClass: "modal__foot__buttons"
  }, [_c('a', {
    staticClass: "button button--primary button--round",
    attrs: {
      "href": "#",
      "onlick": "event.preventDefault()"
    },
    on: {
      "click": _vm.save
    }
  }, [_c('span', [_vm._v("신청하기")])])])])])])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }