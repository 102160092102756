<template>
<div class="schedule-container">
    <!-- 셀러 스케줄 -->
    <matching-schedule-seller v-if="scope.includes('seller')" v-bind="$attrs" @save="$emit('save')" @cancel="$emit('cancel')" @reject="$emit('reject')"></matching-schedule-seller>

    <!-- 바이어 스케줄 -->
    <matching-schedule-buyer v-else-if="scope.includes('buyer')" v-bind="$attrs" @save="$emit('save')"></matching-schedule-buyer>
</div>
</template>
<script>
import MatchingScheduleSeller from "./matching-schedule-seller.vue";
import MatchingScheduleBuyer from "./matching-schedule-buyer.vue";

export default {
    components: {
        MatchingScheduleSeller,
        MatchingScheduleBuyer
    },
    computed: {
        payload(){
            return this.$store.state.payload;
        },
        scope(){
            return this.payload?.scope || [];
        },
    }
}
</script>