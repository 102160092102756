<template>
    <div class="feature-table__row">
        <div class="feature-table__th">
            {{label}}
        </div>
        <div class="feature-table__td">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: ["label"]
};
</script>