var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "schedule-container"
  }, [_vm.scope.includes('seller') ? _c('matching-schedule-seller', _vm._b({
    on: {
      "save": function ($event) {
        return _vm.$emit('save');
      },
      "cancel": function ($event) {
        return _vm.$emit('cancel');
      },
      "reject": function ($event) {
        return _vm.$emit('reject');
      }
    }
  }, 'matching-schedule-seller', _vm.$attrs, false)) : _vm.scope.includes('buyer') ? _c('matching-schedule-buyer', _vm._b({
    on: {
      "save": function ($event) {
        return _vm.$emit('save');
      }
    }
  }, 'matching-schedule-buyer', _vm.$attrs, false)) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }