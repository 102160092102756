<template>
    <div
        v-if="product"
        class="fair-product-view"
    >
        <div
            v-if="showBack"
            class="mb-4"
        >
            <v-btn
                class="pl-2 pr-6"
                @click="$emit('back')"
                color="primary"
                elevation="0"
            >
                <v-icon class="mr-2">{{ mdiChevronLeft }}</v-icon>{{$t("common.뒤로가기")}}
            </v-btn>
        </div>
        <div class="fair-product-view__head">
            <div class="fair-product-view__head-thumb">
                <div class="thumb-slider swiper-container">
                    <div class="swiper-wrapper">
                        <div
                            v-for="(image, index) in product.images"
                            :key="index"
                            class="swiper-slide"
                        >
                            <div class="thumb">
                                <img
                                    v-if="image"
                                    :src="image"
                                    alt=""
                                >
                            </div>
                        </div>
                    </div>
                    <div class="swiper-pagination"></div>
                </div>
            </div>
            <div class="fair-product-view__head-contents">
                <p class="subtit">{{ $t(`common.${product.seller.kind}`) }}</p>
                <p class="tit">{{ product.name[$i18n.locale] }}</p>
                <ul class="fair-product-view__info">
                    <!-- Active 됐을때: 컬러 primary로 설정해주시면되요 -->
                    <product-li :label="$t('common.인증구분')">{{ product.awardKind }}</product-li>
                    <product-li :label="$t('common.제품군')">{{ nav }}</product-li>
                    <product-li :label="$t('common.기업명')">{{ product.seller.companyName[$i18n.locale] }}</product-li>
                    <product-li :label="$t('common.홈페이지')">
                        <a
                            v-on:click="openlink(product.homepage)"
                            target="_blank"
                        >{{ product.homepage }}</a>
                    </product-li>
                </ul>
                <div
                    v-if="scope.includes('buyer')"
                    class="fair-product-view__head-buttons"
                >
                    <!-- Active 됐을때: 컬러 primary로 설정해주시면되요 -->
                    <v-btn
                        class="mx-2"
                        depressed
                        outlined
                        fab
                        small
                        :color="likes.length ? 'pink' : 'grey'"
                        @click="likes.length ? unlike() : like()"
                    >
                        <v-icon>
                            mdi-heart
                        </v-icon>
                    </v-btn>

                    <v-btn
                        depressed
                        rounded
                        color="primary"
                        :disabled="(fair.matchingStatus == '매칭불가' || matchings.length) ? true : false"
                        @click="matching"
                    >
                        <span v-if="!matchings.length">{{$t('common.매칭신청')}}</span>
                        <span v-else>{{$t('common.매칭신청')}}</span>
                    </v-btn>
                </div>
            </div>
        </div>
        <div class="fair-product-view__body">
            <p class="tit color-primary">{{$t('common.prodcut_essential_information')}}</p>
            <div class="feature-table">
                <feature-tr
                    v-if="product.summary[$i18n.locale]"
                    :label="$t('common.주요특징')"
                >
                    <div v-html="replaceNewline(product.summary[$i18n.locale])" />
                </feature-tr>
                <feature-tr :label="$t('common.sales_price')">{{ product.salePrice.format() }} KRW</feature-tr>
                <feature-tr
                    v-if="product.site"
                    :label="$t('common.판매사이트')"
                >
                    <v-btn
                        depressed
                        rounded
                        outlined
                        color="grey"
                        :href="product.site"
                        target="_blank"
                    >{{$t("common.바로가기")}}</v-btn>
                </feature-tr>
                <feature-tr
                    v-if="product.awardAt"
                    :label="$t('common.어워드_선정일')"
                >{{ $dayjs(product.awardAt).format("YYYY-MM-DD") }}</feature-tr>
                <feature-tr
                    v-if="product.manufacture"
                    :label="$t('common.제조방법')"
                >{{ product.manufacture }}</feature-tr>
                <feature-tr
                    v-if="product.publishingAt"
                    :label="$t('common.출시일자')"
                >{{ $dayjs(product.publishingAt).format("YYYY-MM-DD") }}</feature-tr>
                <feature-tr
                    v-if="product.country[$i18n.locale]"
                    :label="$t('common.제조국')"
                >{{ product.country[$i18n.locale] }}</feature-tr>
                <feature-tr
                    v-if="product.size[$i18n.locale]"
                    :label="$t('common.사이즈')"
                >{{ product.size[$i18n.locale] }}</feature-tr>
                <feature-tr
                    v-if="product.color[$i18n.locale]"
                    :label="$t('common.컬러')"
                >{{ product.color[$i18n.locale] }}</feature-tr>
                <feature-tr
                    v-if="product.elements[$i18n.locale]"
                    :label="$t('common.전성분')"
                >{{ product.elements[$i18n.locale] }}</feature-tr>
                <feature-tr
                    v-if="product.homepage"
                    :label="$t('common.홈페이지')"
                >
                    <v-btn
                        depressed
                        rounded
                        outlined
                        color="grey"
                        :href="product.homepage"
                        target="_blank"
                    >{{$t("common.바로가기")}}</v-btn>
                </feature-tr>
                <feature-tr
                    v-if="product.promotion"
                    :label="$t('common.SMC_홍보마당')"
                >
                    <v-btn
                        depressed
                        rounded
                        outlined
                        color="grey"
                        :href="product.promotion"
                        target="_blank"
                    >{{$t("common.바로가기")}}</v-btn>
                </feature-tr>
                <feature-tr
                    v-if="product.content[$i18n.locale]"
                    :label="$t('common.상세내용')"
                >
                    <p v-html="product.content[$i18n.locale]"></p>
                </feature-tr>

            </div>
        </div>
        <div
            v-if="showBack"
            class="mt-6 text-right"
        >
            <v-btn
                class="pl-2 pr-6"
                @click="$emit('back')"
                color="primary"
                elevation="0"
            >
                <v-icon class="mr-2">{{ mdiChevronLeft }}</v-icon>{{$t("common.뒤로가기")}}
            </v-btn>
        </div>

        <schedule-dialog
            ref="scheduleDialog"
            @save="matching"
        ></schedule-dialog>
    </div>
</template>

<script>
import api from "@/api";
import Swiper from "swiper/bundle";
import { mdiChevronLeft } from "@mdi/js";

import FeatureTr from "./feature-tr.vue";
import productLi from "./product-li.vue";
import ScheduleDialog from "./schedule-dialog.vue";

export default {
    components: {
        productLi,
        FeatureTr,
        ScheduleDialog
    },
    props: {
        fair: { default: null },
        product: { default: null },
        showBack: Boolean
    },
    data() {
        return {
            mdiChevronLeft,
            // 기본 정보
            // productInfos: [
            //     { title: '상품군', text: '이미용  >  스킨케어', href: '' },
            //     { title: '기업명', text: 'companyName', href: '' },
            //     { title: '홈페이지', text: '', href: 'http://naturesc.co.kr/' },
            // ],
            // 필수 정보
            // teatures: [
            //     { title: '주요 특징', text: '세계 최고 청정 뉴질랜드의 마누카꿀 성분이 피부를 슈도알테로모나스발효추출물과 트리플 히알루론산이 보습과 수분 밸런스를 도와 건조한 피부에 수분을 선사합니다. 미세먼지 세정력 99.94% 임상시험완료 / 탈모 증상을 완화하는 기능성 케어 샴푸 / 핵심 독자 특허성분인 RCP-8 COMPLEX 성분 함유 / 두피 안티에이징에 도움이 된다는 인체 적용시험완료 / 트리플 안티 솔루션 처방으로 복합적으로 두피를 케어 / 약산성 저자극 샴푸로 피부 자극 및 항산화 테스트 완료 / 실리콘, 설페이트, 파라벤 등 17가지의 인체 유해 성분 無첨가' },
            //     { title: '판매가(소비자가)', text: '35,000 KRW' },
            //     { title: '판매사이트', text: '' },
            //     { title: '어워드 선정일', text: '2021.04.02' },
            //     { title: '출시일자', text: '2019.12.11' },
            //     { title: '제조방법', text: 'OEM' },
            //     { title: '제조국', text: '대한민국' },
            //     { title: '제조업체', text: '코스맥스(주)' },
            //     { title: '사이즈', text: '480ml' },
            //     { title: '컬러', text: '-' },
            //     { title: '전성분', text: '정제수, 소듐C14-16올레핀설포네이트, 다이소듐라우레스설포석시네이트, 라우라미도프로필베타인, 라우릴하이드록시설테인, 부틸렌글라이콜, 라반딘오일,오렌지껍질오일, 광곽향잎추출물, 퀼라자껍질추출물, 드럼스틱씨추출물, 리치과피추출물, 아르간커넬오일, 귀리커넬단백질, 동백나무씨오일, 아보카도오일,드럼스틱씨오일, 인도키노나무껍질추출물, 하이드롤라이즈드콩단백질, 류코노스톡/무발효여과물, 돌콩단백질, 우유단백질, 쌀추출물, 씨솔트, 아스퍼질러스발효물, 빌베리열매/잎추출물, 올리브오일, 검정콩추출물, 흑미추출물, 서양산딸기추출물, 참깨추출물, 검은깨추출물, 하이드롤라이즈드완두콩단백질, 시어버터, 진주가루,데실글루코사이드, 코코-글루코사이드, 소듐클로라이드, 시트릭애씨드, 에틸헥실글리세린, 폴리쿼터늄-10, 라우라마이드미파, 멘톨, 살리실릭애씨드. 트로메타민,폴리쿼터늄-22, 카프릴릴글라이콜, 판테놀, 소듐시트레이트, 소듐코코일이세티오네이트, 1,2-헥산다이올, 글리시레티닉애씨드, 글리세린, 토코페롤, 솔비톨,프로판다이올, 다이소듐석시네이트, 비니거, 글루타믹애씨드, 글라이신, 세린, 아스파틱애씨드, 류신, 알라닌, 라이신, 알지닌, 타이로신, 페닐알라닌, 레시틴,3피토스테롤, 프롤린, 스쿠알란, 트레오닌, 발린, 아이소류신, 히스티딘, 시스테인, 메티오닌, 세라마이드엔피, 에탄올, 다이소듐이디티에이, 리날룰, 리모넨' },
            // ]

            categories: [],
            matchings: [],
            likes: [],
            swiper: undefined
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let { categories } = await api.v1.shop.categories.gets();
            this.categories = categories;

            let { matchings } = await api.v1.me.matchings.gets({
                params: {
                    _fair: this.fair._id,
                    _seller: this.product._seller
                }
            });
            this.matchings = matchings;

            let { likes } = await api.v1.me.likes.gets({
                params: {
                    _fair: this.fair._id,
                    _product: this.product._id
                }
            });

            this.likes = likes;

            this.$nextTick(() => {
                this.swiper = new Swiper(".thumb-slider", {
                    centeredSlides: true,
                    spaceBetween: 0,
                    speed: 300,
                    loop: true,
                    pagination: {
                        el: ".thumb-slider .swiper-pagination",
                        clickable: true
                    },
                    on: {
                        init: function() {
                            if (this.activeIndex < 2) {
                                this.el.querySelector(
                                    ".swiper-pagination"
                                ).style.display = "none";
                            }
                        }
                    }
                    // navigation: {
                    //     nextEl: '.main-visual__next',
                    //     prevEl: '.main-visual__prev'
                    // },
                });
            });
        },

        async like() {
            let { like } = await api.v1.me.likes.post({
                _fair: this.fair._id,
                _product: this.product._id,
                _seller: this.product._seller
            });

            this.likes = [like];
        },

        async unlike() {
            await api.v1.me.likes.delete({
                _id: this.likes[0]._id
            });

            this.likes = [];
        },

        async matching() {
            let { schedule } = await api.v1.me.schedules.get({
                _fair: this.fair._id
            });
            if (schedule) {
                let { matching } = await api.v1.me.matchings.post({
                    _fair: this.fair._id,
                    _product: this.product._id,
                    _seller: this.product._seller
                });

                alert("매칭 신청이 완료되었습니다");
                this.matchings = [matching];
            } else {
                this.$refs.scheduleDialog.fair = this.fair;
                this.$refs.scheduleDialog.open();
            }
        },
        replaceNewline(text) {
            return text.replace(/\r?\n/g, "<br />");
        },
        openlink(link) {
            const target = /https?:\/\//.test(link) ? link : "http://" + link;
            window.open(target, "_blank");
        }
    },
    computed: {
        payload() {
            return this.$store.state.payload || {};
        },
        scope() {
            return this.payload?.scope || [];
        },
        nav() {
            if (!this.categories.length) return;
            if (!this.product._category) return;

            let nav = "";
            let category = this.categories.find(
                ({ _id }) => _id == this.product._category
            );

            for (let _parent of category?._parents) {
                let parent = this.categories.find(({ _id }) => _id == _parent);
                nav = nav
                    ? `${nav} > ${parent.name[this.$i18n.locale]}`
                    : parent.name[this.$i18n.locale];
            }

            return (nav = nav
                ? `${nav} > ${category.name[this.$i18n.locale]}`
                : category.name[this.$i18n.locale]);
        }
    }
};
</script>
<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
@import "@/styles/_variables.scss";

.fair-product-view {
    .v-btn:not(.v-btn--fab) {
        padding-right: 40px;
        padding-left: 40px;
    }
    &__head {
        display: flex;
        .subtit {
            font-size: 24px;
            color: #666;
        }
        &-thumb {
            overflow: hidden;
            position: relative;
            width: 450px;
            height: 450px;
            border: 1px solid $border;
            border-radius: 20px;
        }
        &-thumb .swiper-container {
            height: 100%;
        }
        &-thumb .swiper-container .thumb {
            height: 100%;
        }
        &-thumb .swiper-container .thumb img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &-thumb .swiper-pagination {
            bottom: 20px;
        }
        &-contents {
            flex: 1 1;
            margin-left: 60px;
            padding: 20px 0;
        }
        &-buttons {
            display: flex;
            margin: 0 -5px;
        }
        &-buttons .v-btn {
            margin: 0 5px;
        }
    }
    ::v-deep &__info {
        margin: 40px 0;
        padding: 35px 0;
        font-size: 18px;
        color: #666666;
        border-top: 1px solid $border;
        border-bottom: 1px solid $border;

        li {
            display: flex;
            margin-bottom: 15px;
        }
        li:last-child {
            margin-bottom: 0;
        }
        li .tit {
            flex: 0 0 130px;
            font-size: inherit;
        }
        li .txt {
            flex: 1 1;
        }
        li a {
            color: #000cff;
        }
    }
    &__body {
        margin-top: 50px;
        .tit {
            margin: 10px 0;
            font-size: 18px;
        }
    }

    ::v-deep .feature-table {
        display: table;
        table-layout: fixed;
        border-collapse: collapse;
        width: 100%;
        line-height: 1.8;
        font-size: 14px;
        color: #999999;
        border-top: 1px solid $border-dark;
        &__row {
            display: table-row;
            border-bottom: 1px solid $border;
        }
        &__th {
            display: table-cell;
            padding: 20px;
            width: 210px;
            color: #222222;
        }
        &__td {
            display: table-cell;
            padding: 20px;
            height: 65px;
        }
    }
}
@media (max-width: 1200px) {
    .fair-product-view {
        &__head {
            .subtit {
                font-size: 18px;
            }
            &-thumb {
                width: 360px;
                height: 360px;
            }
            &-contents {
                margin-left: 50px;
            }
        }
        ::v-deep &__info {
            margin: 30px 0;
            padding: 25px 0;
            font-size: 16px;
            li {
                margin-bottom: 12px;
            }
            li .tit {
                flex: 0 0 110px;
            }
        }
    }
}
@media (max-width: 768px) {
    .v-btn:not(.v-btn--fab) {
        flex: 1 1;
    }
    .fair-product-view {
        &__head {
            flex-direction: column;
            .subtit {
                font-size: 16px;
            }
            &-thumb {
                padding-top: 60%;
                width: 100%;
                height: 0;
            }
            &-thumb img {
                position: absolute;
                top: 0;
                left: 0;
            }
            &-contents {
                margin: 25px 0 0;
                padding: 0;
            }
        }
        ::v-deep &__info {
            margin: 20px 0;
            padding: 18px 0;
            font-size: 14px;
            li {
                margin-bottom: 10px;
            }
            li .tit {
                flex: 0 0 80px;
            }
        }
        ::v-deep .feature-table {
            font-size: 13px;
            &__th {
                width: 160px;
            }
        }
    }
}
@media (max-width: 500px) {
    .fair-product-view {
        ::v-deep .feature-table {
            &__th {
                padding: 10px;
                width: 120px;
            }
            &__td {
                padding: 14px 10px;
            }
        }
    }
}
</style>