var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap wrap--mypage"
  }, [_vm.scope.includes('seller') ? _c('sub-visual', {
    attrs: {
      "visual": '셀러 회원 마이페이지'
    }
  }) : _vm._e(), _vm.scope.includes('buyer') ? _c('sub-visual', {
    attrs: {
      "visual": '바이어 회원 마이페이지'
    }
  }) : _vm._e(), _c('div', {
    staticClass: "contents"
  }, [_c('div', {
    staticClass: "ct"
  }, [_c('div', {
    staticClass: "mypage-container"
  }, [_c('mypage-navigation'), _c('div', {
    staticClass: "mypage-contents"
  }, [_c('div', {
    staticClass: "mypage-contents__head"
  }), _c('div', {
    staticClass: "mypage-contents__body"
  }, [_c('fair-view', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var fair = _ref.fair;
        return [_c('div', {
          staticClass: "tab-menu"
        }, _vm._l(_vm.list, function (item) {
          return _c('tab-item', _vm._b({
            key: item.id,
            model: {
              value: _vm.currentId,
              callback: function ($$v) {
                _vm.currentId = $$v;
              },
              expression: "currentId"
            }
          }, 'tab-item', item, false));
        }), 1), fair && _vm.scope.includes('seller') ? _c('div', {
          staticClass: "tab-contents"
        }, [_c('transition', [_c('section', {
          key: _vm.currentId,
          staticClass: "item"
        }, [_vm.currentId == 1 ? _c('product-list', {
          attrs: {
            "fair": fair,
            "_fair": fair._id,
            "_seller": _vm.payload._user,
            "type": "me"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var products = _ref2.products;
              return _vm._l(products, function (product) {
                return _c('product-list-item', {
                  key: product._id,
                  attrs: {
                    "product": product,
                    "hide-buttons": ""
                  }
                });
              });
            }
          }], null, true)
        }) : _vm._e(), _vm.currentId == 2 && !_vm.matching ? _c('matching-list', {
          attrs: {
            "_fair": fair._id
          },
          on: {
            "click": function (_ref3) {
              var matchings = _ref3.matchings,
                matching = _ref3.matching;
              _vm.$data.matchings = matchings;
              _vm.$data.matching = matching;
            }
          }
        }) : _vm._e(), _vm.currentId == 2 && _vm.matching ? _c('matching-schedule', {
          attrs: {
            "fair": fair,
            "matching": _vm.matching,
            "matchings": _vm.matchings
          },
          on: {
            "cancel": function ($event) {
              _vm.matching = null;
            },
            "save": function ($event) {
              _vm.matching = null;
            },
            "reject": function ($event) {
              _vm.matching = null;
            }
          }
        }) : _vm._e()], 1)])], 1) : _vm._e(), fair && _vm.scope.includes('buyer') ? _c('div', {
          staticClass: "tab-contents"
        }, [_c('transition', [_c('section', {
          key: _vm.currentId,
          staticClass: "item"
        }, [_c('product-list', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.currentId == 1 && !_vm.product,
            expression: "currentId == 1 && !product"
          }],
          attrs: {
            "_fair": fair._id,
            "type": "likes"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref4) {
              var products = _ref4.products;
              return _vm._l(products, function (product) {
                return _c('product-list-item', {
                  key: product._id,
                  attrs: {
                    "product": product,
                    "disabled": !_vm.scope.includes('buyer')
                  },
                  on: {
                    "click": function (product) {
                      return _vm.$data.product = product;
                    }
                  }
                });
              });
            }
          }], null, true)
        }), _vm.currentId == 1 && _vm.product ? _c('product-view', {
          attrs: {
            "fair": fair,
            "product": _vm.product,
            "show-back": ""
          },
          on: {
            "back": function ($event) {
              _vm.product = null;
            }
          }
        }) : _vm._e(), _vm.currentId == 2 ? _c('matching-list', {
          attrs: {
            "_fair": fair._id
          }
        }) : _vm._e(), _vm.currentId == 3 ? _c('matching-schedule', {
          attrs: {
            "fair": fair
          }
        }) : _vm._e(), _vm.currentId == 4 ? _c('matching-consulting', {
          attrs: {
            "fair": fair,
            "_fair": fair._id
          }
        }) : _vm._e()], 1)])], 1) : _vm._e()];
      }
    }])
  })], 1)])], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }