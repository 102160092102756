var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.show && _vm.fair ? _c('transition', {
    attrs: {
      "name": "modal",
      "appear": ""
    }
  }, [_c('div', {
    staticClass: "modal-container modal-container--scrollable"
  }, [_c('div', {
    staticClass: "modal-wrapper"
  }, [_c('div', {
    staticClass: "modal"
  }, [_c('div', {
    staticClass: "modal__head"
  }, [_c('h1', {
    staticClass: "modal__title"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("schedule_dialog.title")))]), _c('small', [_vm._v(_vm._s(_vm.$t("schedule_dialog.sub_title")))])]), _c('button', {
    staticClass: "modal__close",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/close-rounded.png",
      "alt": ""
    }
  })])]), _c('div', {
    staticClass: "modal__body"
  }, [_c('matching-schedule', {
    attrs: {
      "fair": _vm.fair
    },
    on: {
      "save": _vm.save
    }
  })], 1), _c('div', {
    staticClass: "modal__foot"
  })])])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }