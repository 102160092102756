var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap wrap--mypage"
  }, [_vm.scope.includes('seller') ? _c('sub-visual', {
    attrs: {
      "visual": _vm.$t('common.셀러 회원 마이페이지')
    }
  }) : _vm._e(), _vm.scope.includes('buyer') ? _c('sub-visual', {
    attrs: {
      "visual": _vm.$t('common.바이어 회원 마이페이지')
    }
  }) : _vm._e(), _c('div', {
    staticClass: "contents"
  }, [_c('div', {
    staticClass: "ct"
  }, [_c('div', {
    staticClass: "mypage-container"
  }, [_c('mypage-navigation'), _c('div', {
    staticClass: "mypage-contents"
  }, [_c('div', {
    staticClass: "mypage-contents__head"
  }), _c('div', {
    staticClass: "mypage-contents__body"
  }, [_c('fair-list', {
    attrs: {
      "type": "mypage"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var fairs = _ref.fairs;
        return _vm._l(fairs, function (fair, index) {
          return _c('fair-list-item', {
            key: fair._id,
            attrs: {
              "fair": fair,
              "to": `/mypage/fairs/${fair._id}`,
              "type": "mypage",
              "color": index % 2 ? 'orange' : 'primary'
            }
          });
        });
      }
    }])
  })], 1)])], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }