<template id="modal-template">
    <transition v-if="show && fair" name="modal" appear>
        <div class="modal-container modal-container--scrollable">
            <div class="modal-wrapper">
                <div class="modal">
                    <div class="modal__head">
                        <h1 class="modal__title">
                            <span>{{$t("schedule_dialog.title")}}</span>
                            <small>{{$t("schedule_dialog.sub_title")}}</small>
                        </h1>
                        <button class="modal__close" type="button" @click="close">
                            <img src="/images/icon/close-rounded.png" alt="">
                        </button>
                    </div>
                    <div class="modal__body">
                        <matching-schedule :fair="fair" @save="save"></matching-schedule>
                    </div>
                    <div class="modal__foot">
                        
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>


<script>
import MatchingSchedule from "./matching-schedule.vue";

export default {
    components: {
        MatchingSchedule
    },
    data() {
        return {
            show: false,
            fair: null
        };
    },
    methods: {
        open(){
            this.show = true;
            document.querySelector('html').setAttribute('data-modal', 'opened');
        },
        close(){
            this.show = false;
            document.querySelector('html').removeAttribute('data-modal');
        },
        save(){
            this.$emit("save");
            this.close();
        },
    }
}
</script>
<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.modal__foot{
    &__buttons { display: flex; justify-content: center; margin-top: 50px; }
    &__buttons .button { padding: 10px 40px 12px; line-height: 1; }
}

</style>