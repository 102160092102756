var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.product ? _c('div', {
    staticClass: "fair-product-view"
  }, [_vm.showBack ? _c('div', {
    staticClass: "mb-4"
  }, [_c('v-btn', {
    staticClass: "pl-2 pr-6",
    attrs: {
      "color": "primary",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(_vm._s(_vm.mdiChevronLeft))]), _vm._v(_vm._s(_vm.$t("common.뒤로가기")) + " ")], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "fair-product-view__head"
  }, [_c('div', {
    staticClass: "fair-product-view__head-thumb"
  }, [_c('div', {
    staticClass: "thumb-slider swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.product.images, function (image, index) {
    return _c('div', {
      key: index,
      staticClass: "swiper-slide"
    }, [_c('div', {
      staticClass: "thumb"
    }, [image ? _c('img', {
      attrs: {
        "src": image,
        "alt": ""
      }
    }) : _vm._e()])]);
  }), 0), _c('div', {
    staticClass: "swiper-pagination"
  })])]), _c('div', {
    staticClass: "fair-product-view__head-contents"
  }, [_c('p', {
    staticClass: "subtit"
  }, [_vm._v(_vm._s(_vm.$t(`common.${_vm.product.seller.kind}`)))]), _c('p', {
    staticClass: "tit"
  }, [_vm._v(_vm._s(_vm.product.name[_vm.$i18n.locale]))]), _c('ul', {
    staticClass: "fair-product-view__info"
  }, [_c('product-li', {
    attrs: {
      "label": _vm.$t('common.인증구분')
    }
  }, [_vm._v(_vm._s(_vm.product.awardKind))]), _c('product-li', {
    attrs: {
      "label": _vm.$t('common.제품군')
    }
  }, [_vm._v(_vm._s(_vm.nav))]), _c('product-li', {
    attrs: {
      "label": _vm.$t('common.기업명')
    }
  }, [_vm._v(_vm._s(_vm.product.seller.companyName[_vm.$i18n.locale]))]), _c('product-li', {
    attrs: {
      "label": _vm.$t('common.홈페이지')
    }
  }, [_c('a', {
    attrs: {
      "target": "_blank"
    },
    on: {
      "click": function ($event) {
        return _vm.openlink(_vm.product.homepage);
      }
    }
  }, [_vm._v(_vm._s(_vm.product.homepage))])])], 1), _vm.scope.includes('buyer') ? _c('div', {
    staticClass: "fair-product-view__head-buttons"
  }, [_c('v-btn', {
    staticClass: "mx-2",
    attrs: {
      "depressed": "",
      "outlined": "",
      "fab": "",
      "small": "",
      "color": _vm.likes.length ? 'pink' : 'grey'
    },
    on: {
      "click": function ($event) {
        _vm.likes.length ? _vm.unlike() : _vm.like();
      }
    }
  }, [_c('v-icon', [_vm._v(" mdi-heart ")])], 1), _c('v-btn', {
    attrs: {
      "depressed": "",
      "rounded": "",
      "color": "primary",
      "disabled": _vm.fair.matchingStatus == '매칭불가' || _vm.matchings.length ? true : false
    },
    on: {
      "click": _vm.matching
    }
  }, [!_vm.matchings.length ? _c('span', [_vm._v(_vm._s(_vm.$t('common.매칭신청')))]) : _c('span', [_vm._v(_vm._s(_vm.$t('common.매칭신청')))])])], 1) : _vm._e()])]), _c('div', {
    staticClass: "fair-product-view__body"
  }, [_c('p', {
    staticClass: "tit color-primary"
  }, [_vm._v(_vm._s(_vm.$t('common.prodcut_essential_information')))]), _c('div', {
    staticClass: "feature-table"
  }, [_vm.product.summary[_vm.$i18n.locale] ? _c('feature-tr', {
    attrs: {
      "label": _vm.$t('common.주요특징')
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.replaceNewline(_vm.product.summary[_vm.$i18n.locale]))
    }
  })]) : _vm._e(), _c('feature-tr', {
    attrs: {
      "label": _vm.$t('common.sales_price')
    }
  }, [_vm._v(_vm._s(_vm.product.salePrice.format()) + " KRW")]), _vm.product.site ? _c('feature-tr', {
    attrs: {
      "label": _vm.$t('common.판매사이트')
    }
  }, [_c('v-btn', {
    attrs: {
      "depressed": "",
      "rounded": "",
      "outlined": "",
      "color": "grey",
      "href": _vm.product.site,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t("common.바로가기")))])], 1) : _vm._e(), _vm.product.awardAt ? _c('feature-tr', {
    attrs: {
      "label": _vm.$t('common.어워드_선정일')
    }
  }, [_vm._v(_vm._s(_vm.$dayjs(_vm.product.awardAt).format("YYYY-MM-DD")))]) : _vm._e(), _vm.product.manufacture ? _c('feature-tr', {
    attrs: {
      "label": _vm.$t('common.제조방법')
    }
  }, [_vm._v(_vm._s(_vm.product.manufacture))]) : _vm._e(), _vm.product.publishingAt ? _c('feature-tr', {
    attrs: {
      "label": _vm.$t('common.출시일자')
    }
  }, [_vm._v(_vm._s(_vm.$dayjs(_vm.product.publishingAt).format("YYYY-MM-DD")))]) : _vm._e(), _vm.product.country[_vm.$i18n.locale] ? _c('feature-tr', {
    attrs: {
      "label": _vm.$t('common.제조국')
    }
  }, [_vm._v(_vm._s(_vm.product.country[_vm.$i18n.locale]))]) : _vm._e(), _vm.product.size[_vm.$i18n.locale] ? _c('feature-tr', {
    attrs: {
      "label": _vm.$t('common.사이즈')
    }
  }, [_vm._v(_vm._s(_vm.product.size[_vm.$i18n.locale]))]) : _vm._e(), _vm.product.color[_vm.$i18n.locale] ? _c('feature-tr', {
    attrs: {
      "label": _vm.$t('common.컬러')
    }
  }, [_vm._v(_vm._s(_vm.product.color[_vm.$i18n.locale]))]) : _vm._e(), _vm.product.elements[_vm.$i18n.locale] ? _c('feature-tr', {
    attrs: {
      "label": _vm.$t('common.전성분')
    }
  }, [_vm._v(_vm._s(_vm.product.elements[_vm.$i18n.locale]))]) : _vm._e(), _vm.product.homepage ? _c('feature-tr', {
    attrs: {
      "label": _vm.$t('common.홈페이지')
    }
  }, [_c('v-btn', {
    attrs: {
      "depressed": "",
      "rounded": "",
      "outlined": "",
      "color": "grey",
      "href": _vm.product.homepage,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t("common.바로가기")))])], 1) : _vm._e(), _vm.product.promotion ? _c('feature-tr', {
    attrs: {
      "label": _vm.$t('common.SMC_홍보마당')
    }
  }, [_c('v-btn', {
    attrs: {
      "depressed": "",
      "rounded": "",
      "outlined": "",
      "color": "grey",
      "href": _vm.product.promotion,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.$t("common.바로가기")))])], 1) : _vm._e(), _vm.product.content[_vm.$i18n.locale] ? _c('feature-tr', {
    attrs: {
      "label": _vm.$t('common.상세내용')
    }
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.product.content[_vm.$i18n.locale])
    }
  })]) : _vm._e()], 1)]), _vm.showBack ? _c('div', {
    staticClass: "mt-6 text-right"
  }, [_c('v-btn', {
    staticClass: "pl-2 pr-6",
    attrs: {
      "color": "primary",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(_vm._s(_vm.mdiChevronLeft))]), _vm._v(_vm._s(_vm.$t("common.뒤로가기")) + " ")], 1)], 1) : _vm._e(), _c('schedule-dialog', {
    ref: "scheduleDialog",
    on: {
      "save": _vm.matching
    }
  })], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }