<template>
    <div class="table-row">
        <div class="table-th">{{ label }}</div>
        <div class="table-td">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: ["label"]
};
</script>
