<template>
	<client-page class="wrap wrap--mypage">
        <!-- 서브비주얼 -->
        <sub-visual v-if="scope.includes('seller')" :visual="'셀러 회원 마이페이지'"></sub-visual>
        <sub-visual v-if="scope.includes('buyer')" :visual="'바이어 회원 마이페이지'"></sub-visual>

		<div class="contents">
			<div class="ct">
				<div class="mypage-container">
					<!-- 네비게이션 메뉴 -->
					<mypage-navigation></mypage-navigation>
					<!-- 페이지 콘텐츠 -->
					<div class="mypage-contents">
						<div class="mypage-contents__head">
							<!-- <h2 class="mypage-contents__title"></h2> -->
						</div>
						<div class="mypage-contents__body">
                            
                            <!-- 상담회 뷰-->
                            <fair-view v-slot:default="{fair}">
                                <div class="tab-menu">
                                    <tab-item
                                        v-for="item in list"
                                        v-bind="item" :key="item.id"
                                        v-model="currentId"/>
                                </div>

                                <!-- 셀러 탭 -->
                                <div v-if="fair && scope.includes('seller')" class="tab-contents">
                                    <transition>
                                        <section class="item" :key="currentId">
                                            <!-- 참여제품 -->
                                            <product-list v-if="currentId == 1" :fair="fair" :_fair="fair._id" :_seller="payload._user" v-slot:default="{products}" type="me">
                                                <product-list-item v-for="product in products" :key="product._id" :product="product" hide-buttons></product-list-item>
                                            </product-list>

                                            
                                            <!-- 매칭현황 -->
                                            <matching-list v-if="currentId == 2 && !matching" :_fair="fair._id" @click="({ matchings, matching }) => { $data.matchings = matchings; $data.matching = matching; }"></matching-list>
                                            <matching-schedule v-if="currentId == 2 && matching" :fair="fair" :matching="matching" :matchings="matchings" @cancel="matching = null" @save="matching = null" @reject="matching = null"></matching-schedule>
                                        </section>
                                    </transition>
                                </div>

                                <!-- 바이어 탭 -->
                                <div v-if="fair && scope.includes('buyer')" class="tab-contents">
                                    <transition>
                                        <section class="item" :key="currentId">
                                            <!-- 관심제품 -->
                                            <product-list v-show="currentId == 1 && !product" :_fair="fair._id" type="likes" v-slot:default="{products}">
                                                <product-list-item v-for="product in products" :key="product._id" :product="product" @click="product => $data.product = product" :disabled="!scope.includes('buyer')"></product-list-item>
                                            </product-list>
                                            <product-view v-if="currentId == 1 && product" :fair="fair" :product="product" show-back @back="product = null"></product-view>
                                            
                                            <!-- 매칭현황 -->
                                            <matching-list v-if="currentId == 2" :_fair="fair._id"></matching-list>

                                            <matching-schedule v-if="currentId == 3" :fair="fair"></matching-schedule>

                                            <matching-consulting v-if="currentId == 4" :fair="fair" :_fair="fair._id"></matching-consulting>
                                        </section>
                                    </transition>
                                </div>
                            </fair-view>

						</div>
					</div>
				</div>
			</div>
		</div>
	</client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import FairView from "@/components/client/fair/fair-view.vue";

import TabItem from "@/components/client/tab/tab-item.vue";
import ProductList from "@/components/client/fair/product-list.vue";
import ProductListItem from "@/components/client/fair/product-list-item.vue";
import ProductView from "@/components/client/fair/fair-product-view.vue";
import MatchingList from "@/components/client/fair/matching-list.vue";
import MatchingSchedule from "@/components/client/fair/matching-schedule.vue";
import MatchingConsulting from "@/components/client/fair/matching-consulting.vue";

export default {
	components: {
		ClientPage,
		SubVisual,
		MypageNavigation,
        FairView,

        TabItem,

        ProductList,
        ProductListItem,
        ProductView,
        
        MatchingList,
        MatchingSchedule,
        MatchingConsulting,
	},
	data() {
		return {
            fairView: false,
            
            matching: null,
            matchings: [],

            product: null,

            currentId: 1,
		};
	},
    computed: {
        list(){
            if(this.scope?.includes("seller")){
                return [
                    { id: 1, label: '참여제품', content: 1 },
                    { id: 2, label: '매칭현황', content: 2 },
                ];
            }
            if(this.scope?.includes("buyer")){
                return [
                    { id: 1, label: '관심제품', content: 1 },
                    { id: 2, label: '매칭현황', content: 2 },
                    { id: 3, label: '매칭스케줄', content: 3 },
                    { id: 4, label: '상담일지', content: 4 },
                ];
            }
        },
        payload(){
            return this.$store.state.payload || {};
        },
        scope(){
            return this.payload?.scope ?? [];
        }
    },
    watch: {
        currentId(){
            this.matching = null;
        }
    }
}
</script>
